import { ObjectValues } from 'type-alias';

import {
  ContentCategoryEnum,
  ContentCategoryEnumObject,
} from 'constants-content';

import { IntegrationProviderEnum } from 'constants-integrations';

export const PERMISSION_KEYS = Object.freeze({
  PERMISSION_ADMIN: 'admin',
  PERMISSION_ANALYTICS: 'analytics',
  PERMISSION_ANALYTICS_ADVANCED: 'analytics.advanced',
  PERMISSION_ANALYTICS_WORK_ORDERS: 'analytics.work.orders',
  PERMISSION_ANALYTICS_ACCESS: 'analytics.access',
  PERMISSION_ANALYTICS_VISITOR_MANAGEMENT: 'analytics.visitor.management',
  PERMISSION_PORTAL_ACCESS: 'portal',
  PERMISSION_PORTAL_ACCESS_MOBILE: 'portal.mobile',
  PERMISSION_PROFILE_UPDATE: 'profile.update',
  PERMISSION_ADDRESS_UPDATE: 'address.update',
  PERMISSION_SETTINGS_UPDATE: 'settings.update',
  PERMISSION_SETTINGS_DEBUGGER: 'settings.debugger',
  PERMISSION_CHANNEL_CREATE: 'channel.create',
  PERMISSION_CHANNEL_DELETE: 'channel.delete',
  PERMISSION_CHANNEL_RELATIONSHIP_READ: 'channel.relationship.read',
  PERMISSION_CHANNEL_RELATIONSHIP_CREATE: 'channel.relationship.create',
  PERMISSION_CHANNEL_RELATIONSHIP_UPDATE: 'channel.relationship.update',
  PERMISSION_CHANNEL_RELATIONSHIP_DELETE: 'channel.relationship.delete',
  PERMISSION_CONTENT_PERK_CREATE: 'content.perk.create',
  PERMISSION_CONTENT_PERK_PUBLISH: 'content.perk.publish',
  PERMISSION_CONTENT_PERK_READ: 'content.perk.read',
  PERMISSION_CONTENT_PERK_UPDATE: 'content.perk.update',
  PERMISSION_CONTENT_PERK_DELETE: 'content.perk.delete',
  PERMISSION_CONTENT_PROMOTION_CREATE: 'content.promotion.create',
  PERMISSION_CONTENT_PROMOTION_PUBLISH: 'content.promotion.publish',
  PERMISSION_CONTENT_PROMOTION_READ: 'content.promotion.read',
  PERMISSION_CONTENT_PROMOTION_UPDATE: 'content.promotion.update',
  PERMISSION_CONTENT_PROMOTION_DELETE: 'content.promotion.delete',
  PERMISSION_CONTENT_NOTICE_CREATE: 'content.notice.create',
  PERMISSION_CONTENT_URGENT_MESSAGE_CREATE: 'content.urgent-message.create',
  PERMISSION_CONTENT_NOTICE_PUBLISH: 'content.notice.publish',
  PERMISSION_CONTENT_NOTICE_READ: 'content.notice.read',
  PERMISSION_CONTENT_NOTICE_UPDATE: 'content.notice.update',
  PERMISSION_CONTENT_NOTICE_DELETE: 'content.notice.delete',
  PERMISSION_CONTENT_CREATE: 'content.create',
  PERMISSION_CONTENT_PUBLISH: 'content.publish',
  PERMISSION_CONTENT_READ: 'content.read',
  PERMISSION_CONTENT_UPDATE: 'content.update',
  PERMISSION_CONTENT_DELETE: 'content.delete',
  PERMISSION_CONTENT_POST_CREATE: 'content.post.create',
  PERMISSION_CONTENT_POST_PUBLISH: 'content.post.publish',
  PERMISSION_CONTENT_POST_READ: 'content.post.read',
  PERMISSION_CONTENT_POST_UPDATE: 'content.post.update',
  PERMISSION_CONTENT_POST_DELETE: 'content.post.delete',
  PERMISSION_CONTENT_INTERACTIONS_READ: 'content.interactions.read',
  PERMISSION_CONTENT_INTERACTIONS_UPDATE: 'content.interactions.update',
  PERMISSION_CONTENT_INTERACTIONS_DELETE: 'content.interactions.delete',
  PERMISSION_CONTENT_FEATURE_RESERVABLE: 'content.features.reservable',
  PERMISSION_CONTENT_FEATURE_MENU: 'content.features.menu',
  PERMISSION_CONTENT_FEATURE_ESSENSYS: 'content.features.essensys',
  PERMISSION_CONTENT_PAGE_CREATE: 'content.page.create',
  PERMISSION_CONTENT_PAGE_PUBLISH: 'content.page.publish',
  PERMISSION_CONTENT_PAGE_READ: 'content.page.read',
  PERMISSION_CONTENT_PAGE_UPDATE: 'content.page.update',
  PERMISSION_CONTENT_PAGE_DELETE: 'content.page.delete',
  PERMISSION_MERCHANT_CREATE: 'merchant.create',
  PERMISSION_MERCHANT_READ: 'merchant.read',
  PERMISSION_MERCHANT_UPDATE: 'merchant.update',
  PERMISSION_MERCHANT_DELETE: 'merchant.delete',
  PERMISSION_WORKPLACE_MEMBERS_INVITE: 'subscribers.invite',
  PERMISSION_USERS_WORKPLACE_MEMBERS_INVITE: 'users.subscribers.invite',
  PERMISSION_USERS_INFO: 'users.info',
  PERMISSION_USERS_WORKPLACE_MEMBERS_READ: 'users.subscribers.read',
  PERMISSION_USERS_WORKPLACE_MEMBERS_SEARCH: 'users.subscribers.search',
  PERMISSION_USERS_WORKPLACE_MEMBERS_DELETE: 'users.subscribers.delete',
  PERMISSION_USERS_ROLE_INVITE: 'users.role.invite',
  PERMISSION_USERS_ROLE_READ: 'users.role.read',
  PERMISSION_USERS_ROLE_SEARCH: 'users.role.search',
  PERMISSION_USERS_ROLE_DELETE: 'users.role.delete',
  PERMISSION_USERS_VERIFY_PRIMARY_EMAIL: 'users.primary-email.verify',
  PERMISSION_USERS_CAN_ADD_OTHER_USERS_TO_TENANTS: 'users.add.tenant.member',
  PERMISSION_USERS_CAN_REMOVE_OTHER_USERS_FROM_TENANTS:
    'users.remove.tenant.member',
  PERMISSION_USERS_PASSWORD_RESET: 'users.password.reset',
  PERMISSION_USERS_INVITE: 'users.invite',
  PERMISSION_USERS_READ: 'users.read',
  PERMISSION_USERS_SEARCH: 'users.search',
  PERMISSION_USERS_DELETE: 'users.delete',
  PERMISSION_MEDIA_READ: 'media.read',
  PERMISSION_MEDIA_CREATE: 'media.create',
  PERMISSION_MEDIA_UPDATE: 'media.update',
  PERMISSION_MEDIA_DELETE: 'media.delete',
  PERMISSION_DOCUMENTS_READ: 'document.read',
  PERMISSION_DOCUMENTS_CREATE: 'document.create',
  PERMISSION_DOCUMENTS_UPDATE: 'document.update',
  PERMISSION_DOCUMENTS_DELETE: 'document.delete',
  PERMISSION_TEMPLATES_READ: 'templates.read',
  PERMISSION_TEMPLATES_CREATE: 'templates.create',
  PERMISSION_TEMPLATES_UPDATE: 'templates.update',
  PERMISSION_TEMPLATES_DELETE: 'templates.delete',
  PERMISSION_LIBRARY_READ: 'library.read',
  PERMISSION_LIBRARY_CREATE: 'library.create',
  PERMISSION_LIBRARY_UPDATE: 'library.update',
  PERMISSION_LIBRARY_DELETE: 'library.delete',
  PERMISSION_GROUPS_READ: 'groups.read',
  PERMISSION_GROUPS_CREATE: 'groups.create',
  PERMISSION_GROUPS_UPDATE: 'groups.update',
  PERMISSION_GROUPS_DELETE: 'groups.delete',
  PERMISSION_GROUP_PRIMARY_LOCATION_UPDATE: 'groups.primaryLocation.update',
  PERMISSION_EMAIL_CREATE: 'email.create',
  PERMISSION_EMAIL_SEND: 'email.send',
  PERMISSION_SECTIONS_CREATE: 'sections.create',
  PERMISSION_SECTIONS_READ: 'sections.read',
  PERMISSION_SECTIONS_UPDATE: 'sections.update',
  PERMISSION_SECTIONS_DELETE: 'sections.delete',
  PERMISSION_SECTIONS_FILTERS: 'sections.filters',
  PERMISSION_PROPERTY_MANAGE_RETAIL: 'property.manage.retail',
  PERMISSION_PROPERTY_MANAGE_COMPANY: 'property.manage.company',
  PERMISSION_PROPERTY_GUESTS_CREATE: 'property.guests.create',
  PERMISSION_PROPERTY_GUESTS_READ: 'property.guests.read',
  PERMISSION_PROPERTY_GUESTS_UPDATE: 'property.guests.update',
  PERMISSION_PROPERTY_GUESTS_DELETE: 'property.guests.delete',
  PERMISSION_PROPERTY_MAINTENANCE_CREATE: 'property.maintenance.create',
  PERMISSION_PROPERTY_MAINTENANCE_READ: 'property.maintenance.read',
  PERMISSION_PROPERTY_MAINTENANCE_UPDATE: 'property.maintenance.update',
  PERMISSION_PROPERTY_MAINTENANCE_DELETE: 'property.maintenance.delete',
  PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE: 'property.hardware.view-update',
  PERMISSION_PIN_MANAGEMENT_VIEW: 'pin-management.view',
  PERMISSION_PIN_MANAGEMENT_CREATE_EDIT_DELETE:
    'pin-management.create.edit.delete',
  PERMISSION_INTEGRATION_CREATE: 'integration.create',
  PERMISSION_INTEGRATION_READ: 'integration.read',
  PERMISSION_INTEGRATION_UPDATE: 'integration.update',
  PERMISSION_INTEGRATION_DELETE: 'integration.delete',
  PERMISSION_METATAG_CREATE: 'metatag.create',
  PERMISSION_METATAG_READ: 'metatag.read',
  PERMISSION_METATAG_UPDATE: 'metatag.update',
  PERMISSION_METATAG_DELETE: 'metatag.delete',
  PERMISSION_PAYMENT_ACCOUNT_CREATE: 'paymentaccount.create',
  PERMISSION_PAYMENT_ACCOUNT_READ: 'paymentaccount.read',
  PERMISSION_PAYMENT_ACCOUNT_UPDATE: 'paymentaccount.update',
  PERMISSION_PAYMENT_ACCOUNT_DELETE: 'paymentaccount.delete',
  PERMISSION_TAB_CREATE: 'tab.create',
  PERMISSION_TAB_READ: 'tab.read',
  PERMISSION_TAB_UPDATE: 'tab.update',
  PERMISSION_TAB_DELETE: 'tab.delete',
  PERMISSION_WEBHOOK_CREATE: 'webhook.create',
  PERMISSION_WEBHOOK_READ: 'webhook.read',
  PERMISSION_WEBHOOK_UPDATE: 'webhook.update',
  PERMISSION_WEBHOOK_DELETE: 'webhook.delete',
  PERMISSION_CHANNEL_MODULE_CREATE: 'channel-module.create',
  PERMISSION_CHANNEL_MODULE_UPDATE: 'channel-module.update',
  PERMISSION_CHANNEL_MODULE_DELETE: 'channel-module.delete',
  PERMISSION_AUDIENCE_CREATE: 'audience.create',
  PERMISSION_AUDIENCE_READ: 'audience.read',
  PERMISSION_AUDIENCE_DELETE: 'audience.delete',
  PERMISSION_AUDIENCE_UPDATE_USE: 'audience.update-use',
  PERMISSION_AUDIENCE_UPDATE_MANAGE: 'audience.update-manage',
  PERMISSION_VISITOR_MANAGEMENT_LOGS_DOWNLOAD_QR_CODE:
    'visitor-management.logs.download.qr-code',
  PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL:
    'visitor-management.logs.read.all',
  PERMISSION_VISITOR_MANAGEMENT_LOGS_READ: 'visitor-management.logs.read',
  PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL:
    'visitor-management.host.select.all',
  PERMISSION_VISITOR_MANAGEMENT_LOGS_EDIT_ALL:
    'visitor-management.logs.edit.all',
  PERMISSION_VISITOR_MANAGEMENT_LOGS_EDIT: 'visitor-management.logs.edit',
  PERMISSION_VISITOR_MANAGEMENT_LOGS_PRINT_VISITOR_PASS:
    'visitor-management.logs.print.visitorpass',
  PERMISSION_PROPERTY_AUTO_SETUP: 'property.auto-setup',
  PERMISSION_PROPERTY_CSV_UPLOAD: 'property.csv-upload',
  PERMISSION_EQUIPMENT_CREATE: 'equipment.create',
  PERMISSION_EQUIPMENT_UPDATE: 'equipment.update',
  PERMISSION_EQUIPMENT_READ: 'equipment.read',
  PERMISSION_EQUIPMENT_DELETE: 'equipment.delete',
  PERMISSION_WORK_ORDERS_MODULE_ACCESS: 'work-orders.module.access',
  PERMISSION_RESERVATIONS_MODULE_ACCESS: 'reservations.module.access',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM:
    'work-orders.service-request.assignable.team',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW:
    'work-orders.service-request.view',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE:
    'work-orders.service-request.create',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_SETTINGS:
    'work-orders.service-request.settings',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS:
    'work-orders.service-request.edit.status',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM:
    'work-orders.service-request.edit.assign-team',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE:
    'work-orders.service-request.edit.assignee',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA:
    'work-orders.service-request.edit.data',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT:
    'work-orders.service-request.link.equipment',
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE:
    'work-orders.service-request.attach.file',
  PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW: 'work-orders.equipment.view',
  PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS: 'work-orders.equipment.settings',
  PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT: 'work-orders.equipment.add-edit',
  PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW: 'work-orders.pm.schedules.view',
  PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE: 'work-orders.pm.schedules.create',
  PERMISSION_WORK_ORDERS_PM_TASK_VIEW: 'work-orders.pm.tasks.view',
  PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS:
    'work-orders.pm.tasks.edit.status',
  PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM:
    'work-orders.pm.tasks.edit.assign-team',
  PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE:
    'work-orders.pm.tasks.edit.assignee',
  PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE:
    'work-orders.pm.tasks.attach.file',
  PERMISSION_WORK_ORDERS_PM_TASK_GENERATE_SERVICE_REQUEST:
    'work-orders.pm.tasks.generate.service-request',
  PERMISSION_ACCESS_CONTROL: 'access-control.admin',
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ASSIGN_ACCESS_MANAGER:
    'access-control.admin.access.assign-access-manager',
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE:
    'access-control.admin.access.enable',
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE:
    'access-control.admin.access.disable',
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY:
    'access-control.admin.access.manage-company',
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN:
    'access-control.admin.groups.assign',
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE:
    'access-control.admin.groups.revoke',
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY:
    'access-control.admin.groups.manage-company',
  PERMISSION_ACCESS_CONTROL_HID: 'access-control.hid',
  PERMISSION_ACCESS_CONTROL_CCURE: 'access-control.ccure',
  PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_VIEW:
    'access-control.accessConfiguration.advancedSettings.view',
  PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_VIEW:
    'access-control.accessConfiguration.defaultAccess.view',
  PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_EDIT:
    'access-control.accessConfiguration.defaultAccess.edit',
  PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_EDIT:
    'access-control.accessConfiguration.advancedSettings.edit',
  PERMISSION_COMPANY_INVOICES: 'billing-payments.invoices.companyInvoices',
  PERMISSION_PRODUCTS_MANAGE: 'billing-payments.products.manage',
  PERMISSION_PRODUCT_EXCEPTIONS_MANAGE: 'billing-payments.exceptions.write',
  PERMISSION_CREDITS_MANAGE: 'billing-payments.credits.manage',
  PERMISSION_BILLING_PAYMENTS_CHARGE_CREATE: 'billing-payments.charges.create',
  PERMISSION_BILLING_PAYMENTS_CHARGE_VOID: 'billing-payments.charges.void',
  PERMISSION_BILLING_PAYMENTS_COMPANY_RECURRING_CHARGES:
    'billing-payments.charges.recurring-charges',
  PERMISSION_CHARGE_CODE_MANAGE: 'billing-payments.chargeCode.manage',
  PERMISSION_CAN_CANCEL_MEMBERSHIPS: 'billing-payments.memberships.cancel',
  PERMISSION_CAN_REFUND_TRANSACTIONS: 'billing-payments.transactions.refund',
  PERMISSION_UNIT_MANAGE: 'unit.manage',
  PERMISSION_FLOOR_MANAGE: 'floor.manage',
  PERMISSION_MESSAGING_AND_MARKETPLACE_MANAGE: 'marketplace.manage',
  ...ContentCategoryEnumObject,
});

export type PermissionKey = ObjectValues<typeof PERMISSION_KEYS>;

export type Permission = {
  key: PermissionKey;
  description: string;
};

// special permissions can be placed on a channel and determine if a user
// has rights on a related channel.
export const SPECIAL_PERMISSIONS = [
  PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_COMPANY,
  PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_RETAIL,
  PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
  PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
];

/* Routing Permissions */

export const permissions: Permission[] = [
  {
    key: PERMISSION_KEYS.PERMISSION_ADMIN,
    description: 'shared.permissions.admin',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ANALYTICS,
    description: 'shared.permissions.analytics',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ANALYTICS_ADVANCED,
    description: 'shared.permissions.analytics.advanced',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ANALYTICS_WORK_ORDERS,
    description: 'shared.permissions.analytics.workOrders',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ANALYTICS_ACCESS,
    description: 'shared.permissions.analytics.access',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ANALYTICS_VISITOR_MANAGEMENT,
    description: 'shared.permissions.analytics.visitor.management',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS,
    description: 'shared.permissions.portal.access',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS_MOBILE,
    description: 'shared.permissions.portal.access.mobile',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROFILE_UPDATE,
    description: 'shared.permissions.profile.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ADDRESS_UPDATE,
    description: 'shared.permissions.address.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_SETTINGS_UPDATE,
    description: 'shared.permissions.settings.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_SETTINGS_DEBUGGER,
    description: 'shared.permissions.settings.debugger',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_CREATE,
    description: 'shared.permissions.channel.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_DELETE,
    description: 'shared.permissions.channel.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_RELATIONSHIP_READ,
    description: 'shared.permissions.channel.relationship.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_RELATIONSHIP_CREATE,
    description: 'shared.permissions.channel.relationship.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_RELATIONSHIP_UPDATE,
    description: 'shared.permissions.channel.relationship.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_RELATIONSHIP_DELETE,
    description: 'shared.permissions.channel.relationship.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PERK_CREATE,
    description: 'shared.permissions.content.perk.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PERK_PUBLISH,
    description: 'shared.permissions.content.perk.publish',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PERK_READ,
    description: 'shared.permissions.content.perk.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PERK_UPDATE,
    description: 'shared.permissions.content.perk.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PERK_DELETE,
    description: 'shared.permissions.content.perk.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PROMOTION_CREATE,
    description: 'shared.permissions.content.promotion.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PROMOTION_PUBLISH,
    description: 'shared.permissions.content.promotion.publish',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PROMOTION_READ,
    description: 'shared.permissions.content.promotion.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PROMOTION_UPDATE,
    description: 'shared.permissions.content.promotion.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PROMOTION_DELETE,
    description: 'shared.permissions.content.promotion.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_NOTICE_CREATE,
    description: 'shared.permissions.content.notice.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_URGENT_MESSAGE_CREATE,
    description: 'shared.permissions.content.urgent-message.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_NOTICE_PUBLISH,
    description: 'shared.permissions.content.notice.publish',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_NOTICE_READ,
    description: 'shared.permissions.content.notice.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_NOTICE_UPDATE,
    description: 'shared.permissions.content.notice.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_NOTICE_DELETE,
    description: 'shared.permissions.content.notice.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_POST_CREATE,
    description: 'shared.permissions.content.post.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_POST_PUBLISH,
    description: 'shared.permissions.content.post.publish',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_POST_READ,
    description: 'shared.permissions.content.post.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_POST_UPDATE,
    description: 'shared.permissions.content.post.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_POST_DELETE,
    description: 'shared.permissions.content.post.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_CREATE,
    description: 'shared.permissions.content.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PUBLISH,
    description: 'shared.permissions.content.publish',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_READ,
    description: 'shared.permissions.content.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_UPDATE,
    description: 'shared.permissions.content.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_DELETE,
    description: 'shared.permissions.content.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_INTERACTIONS_READ,
    description: 'shared.permissions.content.interactions.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_INTERACTIONS_UPDATE,
    description: 'shared.permissions.content.interactions.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_INTERACTIONS_DELETE,
    description: 'shared.permissions.content.interactions.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_FEATURE_RESERVABLE,
    description: 'shared.permissions.content.feature.reservable',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_FEATURE_MENU,
    description: 'shared.permissions.content.feature.menu',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_FEATURE_ESSENSYS,
    description: 'shared.permissions.content.feature.essensys',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PAGE_CREATE,
    description: 'shared.permissions.content.page.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PAGE_PUBLISH,
    description: 'shared.permissions.content.page.publish',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PAGE_READ,
    description: 'shared.permissions.content.page.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PAGE_UPDATE,
    description: 'shared.permissions.content.page.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CONTENT_PAGE_DELETE,
    description: 'shared.permissions.content.page.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MERCHANT_CREATE,
    description: 'shared.permissions.merchant.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MERCHANT_READ,
    description: 'shared.permissions.merchant.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MERCHANT_UPDATE,
    description: 'shared.permissions.merchant.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MERCHANT_DELETE,
    description: 'shared.permissions.merchant.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PAYMENT_ACCOUNT_CREATE,
    description: 'shared.permissions.payment.account.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PAYMENT_ACCOUNT_READ,
    description: 'shared.permissions.payment.account.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PAYMENT_ACCOUNT_UPDATE,
    description: 'shared.permissions.payment.account.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PAYMENT_ACCOUNT_DELETE,
    description: 'shared.permissions.payment.account.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORKPLACE_MEMBERS_INVITE,
    description: 'shared.permissions.workplace.members.invite',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_INVITE,
    description: 'shared.permissions.users.workplace.members.invite',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_INFO,
    description: 'shared.permissions.users.info',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_READ,
    description: 'shared.permissions.users.workplace.members.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_SEARCH,
    description: 'shared.permissions.users.workplace.members.search',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_DELETE,
    description: 'shared.permissions.users.workplace.members.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_ROLE_INVITE,
    description: 'shared.permissions.users.role.invite',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_ROLE_READ,
    description: 'shared.permissions.users.role.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_ROLE_SEARCH,
    description: 'shared.permissions.users.role.search',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_ROLE_DELETE,
    description: 'shared.permissions.users.role.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_INVITE,
    description: 'shared.permissions.users.invite',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_READ,
    description: 'shared.permissions.users.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_SEARCH,
    description: 'shared.permissions.users.search',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_DELETE,
    description: 'shared.permissions.users.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_VERIFY_PRIMARY_EMAIL,
    description: 'shared.permissions.users.primary-email.verify',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_CAN_ADD_OTHER_USERS_TO_TENANTS,
    description: 'shared.permissions.users.add.tenant.member',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_CAN_REMOVE_OTHER_USERS_FROM_TENANTS,
    description: 'shared.permissions.users.remove.tenant.member',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_USERS_PASSWORD_RESET,
    description: 'shared.permissions.users.password.reset',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MEDIA_READ,
    description: 'shared.permissions.media.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MEDIA_CREATE,
    description: 'shared.permissions.media.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MEDIA_UPDATE,
    description: 'shared.permissions.media.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MEDIA_DELETE,
    description: 'shared.permissions.media.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_DOCUMENTS_READ,
    description: 'shared.permissions.document.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_DOCUMENTS_CREATE,
    description: 'shared.permissions.document.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_DOCUMENTS_UPDATE,
    description: 'shared.permissions.document.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_DOCUMENTS_DELETE,
    description: 'shared.permissions.document.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_TEMPLATES_READ,
    description: 'shared.permissions.templates.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_TEMPLATES_CREATE,
    description: 'shared.permissions.templates.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_TEMPLATES_UPDATE,
    description: 'shared.permissions.templates.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_TEMPLATES_DELETE,
    description: 'shared.permissions.templates.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_LIBRARY_READ,
    description: 'shared.permissions.library.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_LIBRARY_CREATE,
    description: 'shared.permissions.library.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_LIBRARY_UPDATE,
    description: 'shared.permissions.library.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_LIBRARY_DELETE,
    description: 'shared.permissions.library.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_GROUPS_READ,
    description: 'shared.permissions.groups.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_GROUPS_CREATE,
    description: 'shared.permissions.groups.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_GROUPS_UPDATE,
    description: 'shared.permissions.groups.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_GROUPS_DELETE,
    description: 'shared.permissions.groups.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_GROUP_PRIMARY_LOCATION_UPDATE,
    description: 'shared.permissions.groups.primaryLocation.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_EMAIL_CREATE,
    description: 'shared.permissions.email.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_EMAIL_SEND,
    description: 'shared.permissions.email.send',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_SECTIONS_CREATE,
    description: 'shared.permissions.sections.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_SECTIONS_READ,
    description: 'shared.permissions.sections.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_SECTIONS_UPDATE,
    description: 'shared.permissions.sections.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_SECTIONS_DELETE,
    description: 'shared.permissions.sections.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_SECTIONS_FILTERS,
    description: 'shared.permissions.sections.filters',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_RETAIL,
    description: 'shared.permissions.property.manage.retail',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_COMPANY,
    description: 'shared.permissions.property.manage.company',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_CREATE,
    description: 'shared.permissions.property.guests.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_READ,
    description: 'shared.permissions.property.guests.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_UPDATE,
    description: 'shared.permissions.property.guests.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_DELETE,
    description: 'shared.permissions.property.guests.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_CREATE,
    description: 'shared.permissions.property.maintenance.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_READ,
    description: 'shared.permissions.property.maintenance.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_UPDATE,
    description: 'shared.permissions.property.maintenance.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_DELETE,
    description: 'shared.permissions.property.maintenance.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE,
    description: 'shared.permissions.property.hardware.view.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PIN_MANAGEMENT_CREATE_EDIT_DELETE,
    description: 'shared.permissions.property.pin.create.edit.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PIN_MANAGEMENT_VIEW,
    description: 'shared.permissions.property.pin.view',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_INTEGRATION_CREATE,
    description: 'shared.permissions.integration.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_INTEGRATION_READ,
    description: 'shared.permissions.integration.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_INTEGRATION_UPDATE,
    description: 'shared.permissions.integration.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_INTEGRATION_DELETE,
    description: 'shared.permissions.integration.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_METATAG_CREATE,
    description: 'shared.permissions.metatag.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_METATAG_READ,
    description: 'shared.permissions.metatag.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_METATAG_UPDATE,
    description: 'shared.permissions.metatag.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_METATAG_DELETE,
    description: 'shared.permissions.metatag.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_TAB_CREATE,
    description: 'shared.permissions.tab.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_TAB_READ,
    description: 'shared.permissions.tab.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_TAB_UPDATE,
    description: 'shared.permissions.tab.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_TAB_DELETE,
    description: 'shared.permissions.tab.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WEBHOOK_CREATE,
    description: 'shared.permissions.webhook.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WEBHOOK_READ,
    description: 'shared.permissions.webhook.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WEBHOOK_UPDATE,
    description: 'shared.permissions.webhook.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WEBHOOK_DELETE,
    description: 'shared.permissions.webhook.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_AUDIENCE_CREATE,
    description: 'shared.permissions.audience.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_AUDIENCE_READ,
    description: 'shared.permissions.audience.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_AUDIENCE_DELETE,
    description: 'shared.permissions.audience.delete',
  },

  {
    key: PERMISSION_KEYS.PERMISSION_AUDIENCE_UPDATE_USE,
    description: 'shared.permissions.audienceUpdate.use',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_AUDIENCE_UPDATE_MANAGE,
    description: 'shared.permissions.audienceUpdate.manage',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_MODULE_CREATE,
    description: 'shared.permissions.channel.module.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_MODULE_UPDATE,
    description: 'shared.permissions.channel.module.update',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHANNEL_MODULE_DELETE,
    description: 'shared.permissions.channel.module.delete',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_DOWNLOAD_QR_CODE,
    description: 'shared.permissions.visitorManagement.logs.downloadQRCode',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_READ,
    description: 'shared.permissions.visitorManagement.logs.read',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL,
    description: 'shared.permissions.visitorManagement.logs.readAll',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL,
    description: 'shared.permissions.visitorManagement.host.selectAll',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_EDIT,
    description: 'shared.permissions.visitorManagement.logs.edit',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_EDIT_ALL,
    description: 'shared.permissions.visitorManagement.logs.editAll',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_PRINT_VISITOR_PASS,
    description: 'shared.permissions.visitorManagement.logs.printVisitorPass',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_AUTO_SETUP,
    description: 'shared.permissions.property.autoSetup',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PROPERTY_CSV_UPLOAD,
    description: 'shared.permissions.property.csvUpload',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL,
    description: 'shared.permissions.accessControl.view',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ASSIGN_ACCESS_MANAGER,
    description:
      'shared.permissions.accessControl.mobileAccess.assignAccessManager',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
    description: 'shared.permissions.accessControl.mobileAccess.enable',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
    description: 'shared.permissions.accessControl.mobileAccess.disable',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    description: 'shared.permissions.accessControl.mobileAccess.manageCompany',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN,
    description: 'shared.permissions.accessControl.accessGroups.assign',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE,
    description: 'shared.permissions.accessControl.accessGroups.revoke',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
    description: 'shared.permissions.accessControl.accessGroups.manageCompany',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_HID,
    description: 'shared.permissions.accessControl.hid',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_CCURE,
    description: 'shared.permissions.accessControl.ccure',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_VIEW,
    description:
      'shared.permissions.accessControl.accessConfiguration.advancedSettings.view',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_VIEW,
    description:
      'shared.permissions.accessControl.accessConfiguration.defaultAccess.view',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_EDIT,
    description:
      'shared.permissions.accessControl.accessConfiguration.defaultAccess.edit',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_EDIT,
    description:
      'shared.permissions.accessControl.accessConfiguration.advancedSettings.edit',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_MODULE_ACCESS,
    description: 'shared.permissions.workOrders.module.access',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM,
    description: 'shared.permissions.workOrders.serviceRequest.assignable.user',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW,
    description: 'shared.permissions.workOrders.serviceRequest.view',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_SETTINGS,
    description: 'shared.permissions.workOrders.serviceRequest.settings',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA,
    description: 'shared.permissions.workOrders.serviceRequest.edit.data',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT,
    description: 'shared.permissions.workOrders.serviceRequest.linkEquipment',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM,
    description: 'shared.permissions.workOrders.serviceRequest.edit.assignTeam',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE,
    description: 'shared.permissions.workOrders.serviceRequest.edit.assignee',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE,
    description: 'shared.permissions.workOrders.serviceRequest.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS,
    description: 'shared.permissions.workOrders.serviceRequest.edit.status',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE,
    description: 'shared.permissions.workOrders.serviceRequest.attachFile',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW,
    description: 'shared.permissions.workOrders.equipment.view',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS,
    description: 'shared.permissions.workOrders.equipment.settings',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT,
    description: 'shared.permissions.workOrders.equipment.addEdit',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW,
    description: 'shared.permissions.workOrders.PM.schedules.view',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE,
    description: 'shared.permissions.workOrders.PM.schedules.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
    description: 'shared.permissions.workOrders.PM.tasks.view',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM,
    description: 'shared.permissions.workOrders.PM.tasks.edit.assignTeam',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE,
    description: 'shared.permissions.workOrders.PM.tasks.edit.assignee',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS,
    description: 'shared.permissions.workOrders.PM.tasks.edit.status',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE,
    description: 'shared.permissions.workOrders.PM.tasks.attachFile',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PRODUCTS_MANAGE,
    description: 'shared.permissions.billingPayments.products.manage',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_PRODUCT_EXCEPTIONS_MANAGE,
    description: 'shared.permissions.billingPayments.exceptions.write',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CREDITS_MANAGE,
    description: 'shared.permissions.billingPayments.credits.manage',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_COMPANY_INVOICES,
    description: 'shared.permissions.billingPayments.invoices.companyInvoices',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_BILLING_PAYMENTS_CHARGE_CREATE,
    description: 'shared.permissions.billingPayments.charges.create',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_BILLING_PAYMENTS_CHARGE_VOID,
    description: 'shared.permissions.billingPayments.charges.void',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_BILLING_PAYMENTS_COMPANY_RECURRING_CHARGES,
    description:
      'shared.permissions.billingPayments.charges.companySubscriptions',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CHARGE_CODE_MANAGE,
    description: 'shared.permissions.billingPayments.chargeCodes.manage',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CAN_CANCEL_MEMBERSHIPS,
    description: 'shared.permissions.billingPayments.subscriptions.cancel',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_CAN_REFUND_TRANSACTIONS,
    description: 'shared.permissions.billingPayments.transactions.refund',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_UNIT_MANAGE,
    description: 'shared.permissions.unit.manage',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_FLOOR_MANAGE,
    description: 'shared.permissions.floor.manage',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_MESSAGING_AND_MARKETPLACE_MANAGE,
    description: 'shared.permissions.marketplace.manage',
  },
  {
    key: PERMISSION_KEYS.PERMISSION_RESERVATIONS_MODULE_ACCESS,
    description: 'shared.permissions.reservations.module.access',
  },
];

Object.values(ContentCategoryEnum).forEach(category => {
  const permission = `category.interaction.${category}`;

  permissions.push({
    // FIXME
    // @ts-expect-error Does not follow permission declaration patterns, does not follow permission naming conventions
    key: permission,
    description: `The user can interact with content with a category of "${category}"`,
  });
});

enum UnsortedPermissionCategoriesEnum {
  WorkOrders = 'work-orders',
}

export const INTEGRATION_DISABLED_PERMISSIONS: {
  [key: string]: { permissions: string[]; text: string };
} = {
  'work orders': {
    permissions: [
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE,
      PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_GENERATE_SERVICE_REQUEST,
    ],
    text: 'web.admin.channel.teamManagement.team.permissions.disabled.workOrders',
  },
};

export const UNSORTED_PERMISSION_CATEGORIES = [
  UnsortedPermissionCategoriesEnum.WorkOrders,
];

// Contains updates to the permissions for a channel's group roles when an integration is added or updated
export const CHANNEL_INTEGRATION_PERMISSION_UPDATES: {
  [key: string]: [{ permissions: string[]; action: string }];
} = {
  [IntegrationProviderEnum.Yardi]: [
    {
      permissions: [
        ...INTEGRATION_DISABLED_PERMISSIONS['work orders'].permissions,
      ],
      action: 'remove',
    },
  ],
  [IntegrationProviderEnum.RealPage]: [
    {
      permissions: [
        ...INTEGRATION_DISABLED_PERMISSIONS['work orders'].permissions,
      ],
      action: 'remove',
    },
  ],
  [IntegrationProviderEnum.Entrata]: [
    {
      permissions: [
        ...INTEGRATION_DISABLED_PERMISSIONS['work orders'].permissions,
      ],
      action: 'remove',
    },
  ],
};
